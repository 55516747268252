<template>
  <a class="v-button">
    <span class="v-button-text"><slot/></span>
  </a>
</template>

<script>
  export default {
    data () {
      return {
      }
    }
  }
</script>