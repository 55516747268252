<template>
  <div class="v-title">
    <slot/>
  </div>
</template>

<script>
  export default {
    data () {
      return {
      }
    }
  }
</script>
