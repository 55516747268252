<template>
  <div class="v-breadcrumbs">
    Breadcrumbs
  </div>
</template>

<script>
  export default {
    data () {
      return {
      }
    }
  }
</script>