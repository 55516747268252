import { render, staticRenderFns } from "./VTestimonial.vue?vue&type=template&id=6a787ff2&"
import script from "./VTestimonial.vue?vue&type=script&lang=js&"
export * from "./VTestimonial.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "VTestimonial.vue"
export default component.exports