<template>
  <div class="v-slab">
    Slab
  </div>
</template>

<script>
  export default {
    data () {
      return {
      }
    }
  }
</script>
